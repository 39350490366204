<template>
  <v-dialog v-model="open" max-width="550">
    <v-card class="py-8">
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <v-icon size="200">{{ mdiAlertCircleOutline }}</v-icon>
        </v-col>
      </v-row>
      <v-card-title class="text-center justify-center text-h5 px-5"> {{ $t(title) }} </v-card-title>
      <v-card-text class="text-center mt-n2 px-5">
        {{ $t(description) }}
      </v-card-text>
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <v-btn @click="$emit('input', false)" class="error">{{ $t('common.ok') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
// Dit component dient als een headsup voor de gebruiker
// Voorbeeld: let op je edit je eigen gebruiker

import { mdiAlertCircleOutline } from '@mdi/js'

export default {
  name: 'AlertDialog',
  props: {
    //v-model voor het openen en sluiten van dit dialoog
    value: Boolean,
    title: String,
    description: String,
  },

  data: () => {
    return {
      open: false,
      mdiAlertCircleOutline: mdiAlertCircleOutline,
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.open = newVal
      },
    },
    open: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('input', newVal)
        }
      },
    },
  },
}
</script>